import RoomPage from "./RoomPage";
import { connect } from "react-redux";
import { actions } from "../../store";

const mapStateToProps = (state, props) => {
  const locationMap = {};
  (state.game.locations || []).forEach(l => {
    locationMap[l.id] = l;
  });
  return {
    player: state.player,
    locationMap,
    status: state.game.status
  };
};

const mapDispatchToProps = dispatch => ({
  updateTraps: traps => dispatch(actions.updateMap(traps))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomPage);
