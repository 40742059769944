import React from "react";
import ShrineImage from "../../assets/images/shrine/shrine_main.jpg";
import TrapLocation from "./TrapLocation";

function ShrineMap({
  status,
  placedTraps,
  focusedSection,
  focusOnSection,
  handleTrapButtonClick
}) {
  const onClickSection = clickedSection => () => focusOnSection(clickedSection);

  const trapIdsBySection = {
    entrance: [1, 2, 3, 4],
    shrine: [5, 6, 19, 20],
    tunnel: [7, 8, 9],
    cave: [10, 11, 12],
    grave: [13, 14, 15, 16, 17, 18]
  };

  const onTrapButtonClick = i => () => handleTrapButtonClick(i);
  const trapButtons = [...Array(20).keys()]
    .map(i => i + 1)
    .map(i => (
      <TrapLocation
        key={i}
        index={i}
        trap={placedTraps[i]}
        enabled={focusedSection && trapIdsBySection[focusedSection].includes(i)}
        onClick={onTrapButtonClick(i)}
      />
    ));

  return (
    <div className="shrine-map-container">
      <div
        className={`shrine-map-focus${
          focusedSection ? " " + focusedSection : ""
        }`}
      >
        <img className="map-image" src={ShrineImage} alt="shrine map" />
        <div className="shrine-map-sections">
          <svg
            className={status === "started" || focusedSection ? "disabled" : ""}
            width="100%"
            height="100%"
            viewBox="0 0 500.85623 553.5083"
            preserveAspectRatio="none"
          >
            <g transform="translate(131.27293,138.92496)">
              <path //Grave
                className="shrine-map-section grave"
                d="m 251.23896,100.14073 0,-96.6716804 C 249.50335,-29.791333 264.5535,-70.612871 187.57961,-69.98214 l -116.195418,0 v 170.12287 z"
                onClick={onClickSection("grave")}
              />
              <path //Cave
                className="shrine-map-section cave"
                d="m 71.384192,-69.98214 h -49.99846 c -31.07895,1.24462 -64.592542,-1.073502 -64.590212,48.582647 v 43.09662 H 71.384192 Z"
                onClick={onClickSection("cave")}
              />
              <path //Tunnel
                className="shrine-map-section tunnel"
                d="m -43.20448,21.697127 v 78.443603 c -0.750666,90.9885 61.328381,145.35825 137.841121,148.73551 H 113.54595 V 187.04168 H 71.384192 V 21.697127 Z"
                onClick={onClickSection("tunnel")}
              />
              <path //Shrine
                className="shrine-map-section shrine"
                d="M 251.23896,100.14073 H 71.384192 v 86.90095 h 42.161758 l 0,61.83456 h 102.54124 l 0,-61.83456 h 35.15177 z"
                onClick={onClickSection("shrine")}
              />
              <path //Entrance
                className="shrine-map-section entrance"
                d="m 113.54595,248.87624 0,67.75282 h 102.54124 l 0,-67.75282 z"
                onClick={onClickSection("entrance")}
              />
            </g>
          </svg>
        </div>
        {status !== "started" && (
          <div
            className={`shrine-map-traps-container${
              focusedSection ? "" : " disabled"
            }`}
          >
            {trapButtons}
          </div>
        )}
      </div>
    </div>
  );
}

export default ShrineMap;
