import React from "react";
import { useSelector } from "react-redux";

import CodePage from "./components/pages/CodePage";
import MapPage from "./components/pages/MapPage";
import ScorePage from "./components/pages/scorePage";

function App() {
  const hasJoined = useSelector(state => Object.keys(state.player).length > 0);
  const status = useSelector(state => state.game.status);

  if (hasJoined === true) {
    if (status === "ended" || status === "won" || status === "lost") {
      return <ScorePage />;
    } else if (status === 'aborted') {
      return <CodePage />;
    }else {
      return <MapPage />;
    }
  } else {
    return <CodePage />;
  }
}

export default App;
