import React from "react";

import { clearGame, clearPlayer } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";

import SocialMediaContainer from "../SocialMediaContainer";


export const ScorePage = () => {
  const dispatch = useDispatch();
  const scores = useSelector(state => (state.game.scores));
  const gameStatus = useSelector(state => (state.game.status));
  const currPlayer = useSelector(state => (state.player.name));
  const killPlayer = useSelector(state => state.game.killPlayer);

  let ranking = 0;

  const setBG = () => {
    switch (gameStatus) {
    case "won":
      return "score-page win";
    case "lost":
      return "score-page lost";
    default:
      return "score-page lost";
    }
  };

  const onReturnToHome = () => {
    dispatch(clearGame());
    dispatch(clearPlayer());
  };
  
  const playerStats = scores.sort((a, b) => b.score - a.score).map(player => {
      if (player) {
        ranking -=-1;
      }
      
      let self = (currPlayer === player.name) ? " self" : "";
      const playerClasses = (killPlayer && player.name === killPlayer) ? `player-${ranking} kill` : `player-${ranking}`;

      return (
        <tr className={playerClasses} key={ranking}>
          <td key={player.ranking} className={`player-ranking player-${ranking}-rank${self}`}>
            {ranking}
          </td>
          <td key={player.name} className={`player-name${self}`}>
            {player.name}
          </td>
          <td key={player.score} className={`player-score${self}`}>
            {player.score}
          </td>
        </tr>
      )
  });
  
  while (playerStats.length < 8){
    ranking -= -1;
    playerStats.push (
      <tr className={`player-${ranking}`} key={ranking}>
        <td key={ranking + 1} className={`player-ranking`}>
          {ranking}
        </td>
        <td key={ranking + 2} className={`player-name`}>
           
        </td>
        <td key={ranking + 3} className={`player-score`}>
          
        </td>
      </tr>
    )
  };

  return (
    <div className={setBG()}>
      <div className="score-page-container">
        <div className="score-page-nav">
          <h1>SPIRIT SCORE</h1>
        </div>
        <div className="score-table-container">
          <table className="score-page players">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Player</th>
                <th>Score</th>
              </tr>             
            </thead>
            <tbody>{playerStats}</tbody>
          </table>
        </div>

        <div className="group">
          <button className="home-button button" onClick={onReturnToHome}>
            HOME
          </button>
          <p>Follow us!</p>
          <SocialMediaContainer />
        </div>
      </div>
    </div>
  );
}

export default ScorePage;
