import React from "react";

import TrapImage from "../../assets/images/trap.png";

function TrapButton({ enabled, onClick }) {
  return (
    <>
      <button className={enabled ? "" : " disabled"} onClick={onClick}>
        <img
          className={`shrine-map-trap-image${enabled ? "" : " disabled"}`}
          src={TrapImage}
          alt="trap-position"
        />
      </button>
      <svg className="shrine-trap-glow disabled" width="500" height="500">
        <defs>
          <radialGradient id="trapGlow">
            <stop offset="0%" stopColor="#FF0000" stopOpacity="1" />
            <stop offset="100%" stopColor="#00FFF00" stopOpacity="0" />
          </radialGradient>
        </defs>
        <rect width="500" height="500" fill="url(#trapGlow)" />
      </svg>
    </>
  );
}

export default TrapButton;
