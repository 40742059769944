import React from "react";
import ArrowImage from "../../assets/images/arrow.png";

function ShrineMapBackButton({ enabled, onClick }) {
  return (
    <img
      className={`shrine-map-back-button${enabled ? "" : " disabled"}`}
      src={ArrowImage}
      onClick={onClick}
      alt="back"
    />
  );
}

export default ShrineMapBackButton;
