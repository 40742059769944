import React, { Component } from "react";
import ReactDOM from "react-dom";
import WS, { InitializeWS, CloseWS } from "../../ws";

import ModalHeader from "../ModalHeader";
import { PlayerLocationBanner } from "../PlayerLocationBanner";
import { PickSectionBanner } from "../PickSectionBanner";
import { Button } from "react-bulma-components";
import ShrineMapBackButton from "../maps/ShrineMapBackButton";

import location from "../../assets/images/location.png";
import whoosh from "../../assets/images/whoosh_btn.png";
import manaBar from "../../assets/images/mana_bar_blue.png";
import manaBarBg from "../../assets/images/mana_bar_bg.png";
import manaLevel from "../../assets/images/mana_level.png";
import fog from "../../assets/images/shrine/traps/fog_icon.png";
import hands from "../../assets/images/shrine/traps/grab_icon.png";

import SelectTrapModal from "../SelectTrapModal";
import ShrineMap from "../maps/ShrineMap";
import shrineLocationImages from "../../assets/images/shrine/location/locations";

const FULL_MANA_SECS = 45;
const CoolDowns = {
  fog: 0.27,
  whoosh: 0.64,
  hands: 1.0
};

const locationNames = {
  0: "Graveyard",
  1: "Cave",
  2: "Shrine",
  3: "Entrance",
  4: "Torii Tunnel"
};

export default class ShrinePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmQuit: false,
      selectedTrap: null,
      mana: 0,
      screenWidth: 0,
      screenHeight: window.innerHeight,
      isShowRealtimeTraps: false,
      isZoomed: false,
      focusedSection: null,
    };
    console.log(this.state)
    this.realtimePicker = React.createRef();
    this.lastScareDate = undefined;
  }

  componentDidMount() {
    const { gameCode, token } = this.props.player;
    InitializeWS(gameCode, token);
    WS().handleOpen = this.handleWSOpen;
    WS().handleMessage = this.handleWSMessage;

    this.checkSession();
    window.addEventListener("resize", this.onResizeScreen);

    if (this.props.status === "started") {
      this.onResizeScreen();
      this.startTimer();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeScreen);
  }

  onResizeScreen = () => {
    this.setState({ screenHeight: window.innerHeight });
    const date = ReactDOM.findDOMNode(this.realtimePicker.current);

    if (date) {
      this.setState({ screenWidth: date.offsetWidth });
    }
  };



  componentWillReceiveProps(props) {
    const { status } = props;
    if (status !== this.props.status && status === "started") {
      // start mana refill
      this.lastScareDate = new Date();
      this.onResizeScreen();
      this.startTimer();
    }
  }

  //TODO: Implement Player Counter

  startTimer = () => {
    if (!this.timer) {
      this.lastTime = new Date();
      this.timer = setInterval(() => {
        const date = new Date();
        let dt = date - this.lastTime;
        this.lastTime = date;
        dt /= 1000;
        let mana = this.state.mana + dt / FULL_MANA_SECS;
        if (mana > 1) {
          mana = 1;
          clearInterval(this.timer);
          this.timer = undefined;
        }
        this.setState({ mana });
      }, 1000 / 60);
    }
  };

  checkSession = async () => {
    const { clearPlayer, player, setGame } = this.props;
    const { gameCode, token } = player;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(
        `${process.env.REACT_APP_API_URL}/api/game/map?gameCode=${gameCode}&token=${token}`,
        {
          method: "GET",
          headers: headers
        }
      );

      if (r.status !== 200) {
        clearPlayer();
        return;
      }
      setGame(await r.json());
    } catch (e) {
      clearPlayer();
    }
  };

  handleWSOpen = () => {
    console.log("connected websocket");
  };

  handleWSMessage = msg => {
    const { setKillPlayer, setPlayerRoom, setStatus, updateTraps, setScores} = this.props;
    msg = JSON.parse(msg);

    switch (msg.event) {
      case "choose_trap":
        updateTraps(msg.locations);
        break;
      case "start":
        setStatus("started");
        break;
      case "player_room":
        setPlayerRoom(msg.room);
        break;
      case "end":
        CloseWS();
        setScores(msg.scores);
        setStatus(msg.status);
        if (msg.killPlayer) {
          setKillPlayer(msg.killPlayer);
        }
        break;
      default:
        break;
    }
  };

  onSelectRealtimeTrap = (gameCode, trap, name, mana) => e => {
    const coolDown = CoolDowns[trap];
    if (mana < coolDown) {
      return;
    }

    WS().sendRealTimeTrap(gameCode, trap, name);
    this.setState({
      mana: mana - coolDown
    });
    // reset timer
    this.lastScareDate = new Date();
    if (!this.timer) {
      this.startTimer();
    }
  };

  focusOnSection = section => this.setState({ focusedSection: section });

  forceClearPlayer = async () => {
    const { clearPlayer } = this.props;
    clearPlayer();
  };

  setConfirmQuit = confirmQuit => () => {
    this.setState({ confirmQuit: confirmQuit });
  };

  setSelectedTrap = i => {
    this.setState({ selectedTrap: i });
  };

  onSelectTrap = selectedLocation => trap => async () => {
    const { player, updateTraps } = this.props;

    this.setSelectedTrap(null);

    const trapTypes = {
      headless: 1,
      thunder: 2,
      scream: 3
    };

    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(`${process.env.REACT_APP_API_URL}/api/game/traps`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          ...player,
          location: Number(selectedLocation),
          trap: trapTypes[trap]
        })
      });
      if (r.status !== 200) {
        this.setState({ error: "Could Not Choose Trap!" });
        return;
      }

      r = await r.json();
      updateTraps(r.locations);

      this.setState({ selectedLocation: null });
    } catch (e) {
      this.setState({ error: "Could Not Place Trap!" });
      return;
    }
  };

  renderPlayerRoom() {
    const { playerRoom } = this.props; //actual in-game player
    if (!playerRoom) {
      return null;
    }

    const { x, y } = {
      //Need new coords for shrine
      1: { x: 28, y: 33 },
      2: { x: 25, y: 52 },
      3: { x: 64, y: 20 },
      4: { x: 69, y: 38 },
      5: { x: 77.5, y: 63 },
      6: { x: 45.5, y: 66 },
      7: { x: 35.5, y: 35 },
      8: { x: 30, y: 54 },
      9: { x: 70, y: 24 },
      10: { x: 75, y: 47 },
      11: { x: 78, y: 68 },
      12: { x: 55, y: 65 }
    }[playerRoom];

    return (
      <img
        className="player-location-icon"
        style={{ top: `${y}%`, left: `${x}%` }}
        src={location}
        alt="player_location"
      />
    );
  }

  renderRealtimeChoices() {
    const { status, player } = this.props;
    const { mana, screenWidth } = this.state;
    const { gameCode } = player;
    const level = 90 * mana;
    let opacity = 0.65;
    const flash = 0.7 / FULL_MANA_SECS;
    Object.values(CoolDowns).forEach(v => {
      if (v < mana && mana < v + flash) {
        const factor = 1 - (mana - v) / flash;
        opacity = factor + (1 - factor) * opacity;
      }
    });
    if (mana === 1) {
      opacity = 1;
    }

    return (
      <div
        ref={this.realtimePicker}
        className="realtime-picker-container"
        style={{ bottom: status === "started" ? 0 : "-50vw" }}
      >
        <p
          className="realtime-picker-header"
          style={{ fontSize: screenWidth * 0.034 }}
        >
          Tap a Scare Power below to frighten the VR Player
        </p>
        <div className="mana-bar-container">
          <div
            className="mana-bar-active-wrapper"
            style={{ width: `${level}%` }}
          >
            <img
              className="mana-bar-active"
              style={{ minWidth: screenWidth * 0.9 }}
              src={manaBar}
              alt="mana-bar"
            />
          </div>
          <img className="mana-bar" src={manaBarBg} alt="mana-bar-bg" />
          <img
            className="mana-level"
            style={{
              height: screenWidth * 0.2,
              top: -screenWidth * 0.025,
              left: `${level + 1}%`,
              opacity
            }}
            src={manaLevel}
            alt="mana-level"
          />
        </div>
        <div className="realtime-picker">
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.fog ? 0.3 : 1,
              cursor: mana < CoolDowns.fog ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "fog", player.name, mana)}
          >
            <img className="realtime-trap-button" src={fog} alt="fog" />
            <p className="trap-label">
              {" "}
              Fog{" "}
            </p>
          </div>
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.whoosh ? 0.3 : 1,
              cursor: mana < CoolDowns.whoosh ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "whoosh", player.name, mana)}
          >
            <img className="realtime-trap-button" src={whoosh} alt="whoosh" />
            <p className="trap-label">
              {" "}
              Whoosh{" "}
            </p>
          </div>
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.hands ? 0.3 : 1,
              cursor: mana < CoolDowns.hands ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "hands", player.name, mana)}
          >
            <img className="realtime-trap-button" src={hands} alt="hands" />
            <p className="trap-label">
              {" "}
              Hands{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { player, playerRoom, status } = this.props;
    const {
      confirmQuit,
      screenWidth,
      selectedTrap,
      focusedSection
    } = this.state;

    return (
      <div className="black-bg map-page-container">
        <div className="map-container">
          <header className="banner blue-bg shrine">
            {status === "started" ? (
              <PlayerLocationBanner
                roomName={locationNames[playerRoom]}
                screenWidth={screenWidth}
                onCloseClicked={this.setConfirmQuit(true)}
              />
            ) : (
              <PickSectionBanner
                name={player.name ? player.name.toUpperCase() : undefined}
              />
            )}
            <ShrineMapBackButton
              enabled={focusedSection !== null}
              onClick={() => this.focusOnSection(null)}
            />
          </header>

          {/* Quit modal */}
          <div className={`modal ${confirmQuit === true && "is-active"}`}>
            <div className="modal-background"></div>
            <div
              className="modal-card quit-container"
              style={{ overflow: "visible" }}
            >
              <ModalHeader
                message="Quit game?"
                onClose={this.setConfirmQuit(false)}
              />
              <section className="modal-card-body trap-modal-container blue-bg">
                <Button
                  className="is-primary is-fullwidth is-large"
                  onClick={this.forceClearPlayer}
                >
                  Yes
                </Button>
                <Button
                  className="is-primary is-fullwidth is-large"
                  onClick={this.setConfirmQuit(false)}
                >
                  No
                </Button>
              </section>
            </div>
          </div>

          {status === "started" && (
            <p className="floor-label">Shrine Overview</p>
          )}

          {status !== "started" && selectedTrap && (
            <SelectTrapModal
              enabled={selectedTrap !== null}
              locationImage={shrineLocationImages[selectedTrap]}
              handleTrapSelection={this.onSelectTrap(selectedTrap)}
              onClose={() => this.setSelectedTrap(null)}
            />
          )}

          <ShrineMap
            status={status}
            placedTraps={this.props.trapLocations}
            focusedSection={status !== "started" ? focusedSection : null}
            focusOnSection={this.focusOnSection}
            handleTrapButtonClick={this.setSelectedTrap}
          />

          {this.renderPlayerRoom()}
          <div className="buttons-container">
            {selectedTrap !== null && (
              <button className="button is-dark is-rounded is-medium">
                {" "}
                Clear Trap{" "}
              </button>
            )}
          </div>
          {this.renderRealtimeChoices()}
        </div>
      </div>
    );
  }
}
