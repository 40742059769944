export const getMap = async (code, token) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  let res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/game/map?gameCode=${code}&token=${token}`,
    {
      method: "GET",
      headers: headers
    }
  );

  if (res.status !== 200) {
    throw new Error(res.text());
  }

  return await res.json();
};

export const joinGame = async (code, name) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");

  let res = await fetch(`${process.env.REACT_APP_API_URL}/api/game/join`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ gameCode: code, name: name.toUpperCase() })
  });

  if (res.status !== 200) {
    throw new Error(res.text());
  }
  
  return await res.json();
};
