import React, { useState } from "react";

export function JoinGameForm({ onJoin }) {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const validateName = name => name.target.value.toUpperCase().slice(0, 10);

  const validateCode = code =>
    code.target.value
      .toUpperCase()
      .replace(/(\W|\d)/, "")
      .slice(0, 4);

  return (
    <div className="join-game-form">
      <input
        className="input"
        type="text"
        value={code}
        placeholder="Code"
        onChange={code => setCode(validateCode(code))}
      />
      <input
        className="input"
        type="text"
        value={name}
        placeholder="Name"
        onChange={name => setName(validateName(name))}
      />
      <button
        className="button"
        onClick={() => onJoin(code, name)}
        disabled={code.length !== 4 || name.length === 0}
      >
        Play
      </button>
    </div>
  );
}
