import React from "react";

export const TrapButton = ({ label, image, onClick }) => (
  <div className="trap-button-container" onClick={onClick}>
    <div className="trap-button-wrapper">
      <img className="trap-button" src={image} alt={label} />
    </div>
    <p className="trap-label">
      {label}
    </p>
  </div>
);

export default TrapButton;
