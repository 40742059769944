import room1 from './rooms/1.jpg'
import room2 from './rooms/2.jpg'
import room3 from './rooms/3.jpg'
import room4 from './rooms/4.jpg'
import room5 from './rooms/5.jpg'
import room6 from './rooms/6.jpg'
import room7 from './rooms/7.jpg'
import room8 from './rooms/8.jpg'
import room9 from './rooms/9.jpg'
import room10 from './rooms/10.jpg'
import room11 from './rooms/11.jpg'
import room12 from './rooms/12.jpg'

export const roomImages = {
    1: room1,
    2: room2,
    3: room3,
    4: room4,
    5: room5,
    6: room6,
    7: room7,
    8: room8,
    9: room9,
    10: room10,
    11: room11,
    12: room12
};
export default roomImages;
