import React from "react";

export const PlayerLocationBanner = ({
  roomName,
  screenWidth,
  onCloseClicked
}) => (
  <>
    <div className="trap-modal-header banner-header">
      <div className="trap-modal-header-left"></div>
      <div className="trap-modal-header-center flex flex-col">
        <p
          className="player-location-label"
          style={{ fontSize: Math.min(screenWidth * 0.04, 18) }}
        >
          Player location:
        </p>
        <p
          className="player-location-label"
          style={{ fontSize: Math.min(screenWidth * 0.04, 18) }}
        >
          <span className="room-name">{roomName}</span>
        </p>
      </div>
      <div className="trap-modal-header-right">
        <button
          className="delete is-large"
          aria-label="close"
          onClick={onCloseClicked}
        ></button>
      </div>
    </div>
  </>
);
