import React from "react";

export const ErrorMessage = ({ message, onClick }) => (
  <div className="notification is-danger flash-message has-text-centered">
    <button className="delete" onClick={onClick} />
    {message}
  </div>
);

export default ErrorMessage;
