import React from "react";

// import fb from "../assets/images/fb.png";
// import ig from "../assets/images/ig.png";

import fb from "../assets/images/social-media/facebook-transparent.png";
import ig from "../assets/images/social-media/instagram-transparent.png";
import twitterIcon from "../assets/images/social-media/twitter-transparent.png";
import discordIcon from "../assets/images/social-media/discord-transparent.png";

import SocialMediaLink from "./SocialMediaLink";

export const SocialMediaContainer = () => (
  <div className="social-media-container">
    <SocialMediaLink
      href="https://urlgeni.us/facebook/XwzG"
      src={fb}
      alt="facebook"
    />
    <SocialMediaLink
      href="https://urlgeni.us/instagram/XwzG"
      src={ig}
      alt="instagram"
    />
    <SocialMediaLink
      href="https://twitter.com/reikosfragments"
      src={twitterIcon}
      alt="twitter"
    />
    <SocialMediaLink
      href="https://discord.gg/SJRS7Nq"
      src={discordIcon}
      alt="discord"
    />
  </div>
);

export default SocialMediaContainer;
