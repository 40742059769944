import React from "react";

import PlacedTrap from "./PlacedTrap";
import TrapButton from "./TrapButton";

function TrapLocation({ index, trap, enabled, onClick }) {
  return (
    <div className={`shrine-map-trap-container trap${index}`}>
      <TrapButton enabled={!trap && enabled} onClick={onClick} />
      {trap && trap.type && trap.player && (
        <PlacedTrap type={trap.type} player={trap.player} />
      )}
    </div>
  );
}

export default TrapLocation;
