import location1 from './locations/1.jpg'
import location2 from './locations/2.jpg'
import location3 from './locations/3.jpg'
import location4 from './locations/4.jpg'
import location5 from './locations/5.jpg'
import location6 from './locations/6.jpg'
import location7 from './locations/7.jpg'
import location8 from './locations/8.jpg'
import location9 from './locations/9.jpg'
import location10 from './locations/10.jpg'
import location11 from './locations/11.jpg'
import location12 from './locations/12.jpg'
import location13 from './locations/13.jpg'
import location14 from './locations/14.jpg'
import location15 from './locations/15.jpg'
import location16 from './locations/16.jpg'
import location17 from './locations/17.jpg'
import location18 from './locations/18.jpg'
import location19 from './locations/19.jpg'
import location101 from './locations/101.jpg'
import location102 from './locations/102.jpg'
import location103 from './locations/103.jpg'
import location104 from './locations/104.jpg'
import location105 from './locations/105.jpg'
import location106 from './locations/106.jpg'
import location107 from './locations/107.jpg'
import location108 from './locations/108.jpg'
import location109 from './locations/109.jpg'
import location110 from './locations/110.jpg'
import location111 from './locations/111.jpg'
import location112 from './locations/112.jpg'
import location113 from './locations/113.jpg'
import location114 from './locations/114.jpg'
import location115 from './locations/115.jpg'
import location116 from './locations/116.jpg'
import location117 from './locations/117.jpg'

export const locationImages = {
    1: location1,
    2: location2,
    3: location3,
    4: location4,
    5: location5,
    6: location6,
    7: location7,
    8: location8,
    9: location9,
    10: location10,
    11: location11,
    12: location12,
    13: location13,
    14: location14,
    15: location15,
    16: location16,
    17: location17,
    18: location18,
    19: location19,
    101: location101,
    102: location102,
    103: location103,
    104: location104,
    105: location105,
    106: location106,
    107: location107,
    108: location108,
    109: location109,
    110: location110,
    111: location111,
    112: location112,
    113: location113,
    114: location114,
    115: location115,
    116: location116,
    117: location117,
};

export default locationImages;
