import { connect } from "react-redux";
import ShrinePage from "./ShrinePage";
import { actions } from "../../store";

const mapStateToProps = state => {
  const locationTypes = {
    1: "headless",
    2: "thunder",
    3: "scream"
  };
  const trapLocations = {};

  if (state.game.locations) {
    state.game.locations.forEach(
      location =>
        (trapLocations[location.id] = {
          type: locationTypes[location.trap],
          typeId: location.trap,
          player: location.player
        })
    );
  }

  return {
    player: state.player,
    playerRoom: state.game.playerRoom,
    trapLocations: trapLocations,
    status: state.game.status,
    state: state
  };
};

const mapDispatchToProps = dispatch => ({
  clearPlayer: () => dispatch(actions.clearPlayer()),
  clearGame: () => dispatch(actions.clearGame()),
  setGame: game => dispatch(actions.setGame(game)),
  setKillPlayer: killPlayer => dispatch(actions.setKillPlayer(killPlayer)),
  setPlayerRoom: room => dispatch(actions.setPlayerRoom(room)),
  setScores: scores => dispatch(actions.setScores(scores)),
  setStatus: status => dispatch(actions.setStatus(status)),
  updateTraps: traps => dispatch(actions.updateMap(traps))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShrinePage);
