export const CLEAR_GAME = "CLEAR_GAME";
export const SET_GAME = "SET_GAME";
export const SET_KILL_PLAYER = "SET_KILL_PLAYER";
export const SET_PLAYER_ROOM = "SET_PLAYER_ROOM";
export const SET_SCORES = "SET_SCORES";
export const SET_STATUS = "SET_STATUS";
export const UPDATE_MAP = "UPDATE_MAP";

export const CLEAR_PLAYER = "CLEAR_PLAYER";
export const SET_PLAYER = "SET_PLAYER";

export const clearGame = () => ({ type: CLEAR_GAME });
export const setGame = game => ({ type: SET_GAME, game });
export const setPlayerRoom = room => ({ type: SET_PLAYER_ROOM, room });
export const setStatus = status => ({ type: SET_STATUS, status });
export const updateMap = locations => ({ type: UPDATE_MAP, locations });
export const setScores = scores => ({ type: SET_SCORES, scores });
export const setKillPlayer = killPlayer => ({ type: SET_KILL_PLAYER, killPlayer })

export const clearPlayer = () => ({ type: CLEAR_PLAYER });
export const setPlayer = (gameCode, token, name) => ({
  type: SET_PLAYER,
  gameCode,
  token,
  name
});
